.subheader{
    background:linear-gradient(90deg, rgba(255, 153, 0, 0.2) 0%, rgba(255, 153, 0, 0) 100%);;
   margin-left: 44px;
   margin-right: 44px;
   padding: 10px;
   border-radius: 8px;
   padding-left: 20px;
}
.middleHeader{
    display: flex;
  background-color: blueviolet;
  margin-left: 44px;
   margin-right: 44px;
   padding: 10px;
   border-radius: 8px;
   margin-top: 20px;
   justify-content: space-between;
}
.itemmiddleHeader{
    background-color: #f1f1f1;
    margin: 10px;
    text-align: center;
   
    width: 200px;
    
}

.col {
    background-color:#0099cc;
    height: 100px;
    margin-right: 5px;
}

.middleContainer{
    /* margin-left: 45px;
    margin-right: 45px; */
    margin-top: 12px;
    background: #F0F2F5;
    border-radius: 12px;
    margin-bottom: 30px;
    margin-right: 18px;
    height: 46px;

   
}
.home_heading{
    font-weight: 400;
    font-size: 24px;
padding-bottom: 17px;
}

.tablebody{
    margin-left: 44px;
   margin-right: 44px;
   margin-top: 40px;
}
.tableHeader{
    border-radius: 8px 8px 0px 0px;
    background-color: #F5F1E8; 
}
.table_List{
    background-color: white;
}
.table_List_tr{
    padding-top: 20px;
}
tr{
    padding: 200px;
}

.sub-header {
    display: flex;
    justify-content: space-between;
    grid-gap: 6px;
}

.sub-header-value1 {
    display: flex;
    border-radius: 8px 0 0 8px;
    background: #3788d8;
    align-items: center;
    justify-content: center;
    padding: 30px 8px 30px 8px;
    transition: box-shadow .3s;
}
.sub-header-value1:hover{
   box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); 
}
.sub-header-value {
    display: flex;
    border-radius: 0;
    background: transparent;
    align-items: center;
    justify-content: center;
}
.sb-active{
    color: #558194 !important;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    height: 38px !important;
    justify-content: center;
    align-items: center;
    display: flex;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
}
.sub-header-value:hover {
    font-size: 1.2vw !important;
    color: #558194 !important;
    background: #fff;
    border-radius: 10px;
}
.sub-header-value-last {
    display: flex;
    border-radius: 0 8px 8px 0;
    background: #3788d8;
    align-items: center;
    justify-content: center;
    padding: 30px 8px 30px 8px;
}
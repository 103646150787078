.left-side-section{
    width: 19.666%; /* 200 */
    width: calc( 13.5em ); /* 200 */
    float: left;
    position: relative;
}
.right_side_section{
	width: 75%; /* 960 */
    width: calc( 100% - 20em ); /* 240 */
     float: right;
    padding: 22px 0px 56px 0px;;
}
.side_bg{
    width: 19.666%; /* 200 */
	position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
	padding: 1rem 0;
	height: 100vh;
	background: #fff;
	color: #2D232E;
	/* border-right: 1px solid rgba(45, 35, 46, 0.2); */

    border: 1px solid #EFF0F6;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 0px;
}
.logo_im{
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-bottom: 17%
}
.side_bg li{
    list-style: none;
    width: 90%;
    margin: 0 auto;
}

/* .down_icon{
    margin-left: 40px;
} */
.down_icon_sidebar {
    margin-right: 0px !important;
    margin-left: 24px;
   
}
.down_icon_sidebar img{
    transform: rotate(0deg);

}
.side_bg li span{
    margin-right: 20px;
}
.side_bg li img{
    width: 16px;
    height: 17px;
}
.logout_dropdown{
    background-color: transparent;
   color: #333333;
    border: 0px;
    display: flex;
    align-items: baseline;
}
.dropdown_but {
    background-color: transparent;
    color: #333333;
    border: 0px;
}

.btn-primary:hover{
background-color: transparent;
/* color: #fff; */
}
.btn-primary:focus {
    background-color: transparent;
   color: #333333;
    border-color: transparent;
    box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 0%) !important;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
    background-color: transparent;
    border: 0px;
   color: #333333;
}
.dropdown_but.dropdown-toggle::after{
    display: none;
}
.dropdown_but.btn{
    padding: 0.375rem 0.2rem;
}
.dropdown-menu{
    min-width: 13rem !important;

}
.dropdown_but .dropdown-item.active, .dropdown-item:active{
background-color: transparent;
color: #333333;
}
.profile_logo{
    background: #558194;
    border-radius: 50%;
    color: #fff;
    width: 39px;
    height: 39px;
    display: inline-block;
    padding-top: 7px;
    margin-right: 14px !important;
    text-align: center;
}
.logout_dropdown.dropdown-toggle::after{
    margin-left: 3.255em;
}

.logout_dropdown.btn{
    padding: 0.375rem 1.5rem;
}
.profile_bg{
    background: #5581942b;
border-radius: 8px;
padding: 4% 3% !important;
width: 96% !important;
margin-right: 3% !important;

}
.logout_dropdown .dropdown-menu.show{
width: 100%;
}
.logout_dropdown .dropdown-item.active, .dropdown-item:active{
    background-color: transparent;
    color: #333333;
    }
.top_link{
    margin-left: 13px;
    width: 91%;
}
.bottom_link{
    position: absolute;
    bottom: 46px;
    width: 91%;
}
.side_bg li a{
    text-decoration: none;
    color: #333333;
    width: 100%;
    display: block;
    padding: 5% 5% 5% 14%;
    margin-bottom: 20px;
    font-size: 1vw;
    font-weight: 400;
    -webkit-transition: all 0.3s ease-in-out;
-moz-transition: all 0.3s ease-in-out;
-o-transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
}
/* .side_bg li a .btn-primary:hover{
    color: #fff !important; 
} */
.side_bg li a:hover{
    background: #558194;
    border-radius: 4px;
    cursor: pointer;
    color: #fff !important;
    font-weight: 600;
    -webkit-transform: scale(1.1);
-moz-transform: scale(1.1);
-o-transform: scale(1.1);
transform: scale(1.1);
-webkit-transition: all 0.3s ease-in-out;
-moz-transition: all 0.3s ease-in-out;
-o-transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
}
.side_bg li a.active{
    background: #558194;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    color: #fff !important; 
}
.side_bg li .dropdown:hover{
    color: #fff !important;   
}

.side_bg li a:hover .sidebar-link-icon{
	filter:  brightness(0) invert(1);
}
.side_bg li a.active .sidebar-link-icon{
	filter:  brightness(0) invert(1);
} 
.type-dropdown {
    text-decoration: none;
    color: #333333;
    width: 100%;
    display: block;
    padding: 5% 5% 5% 14%;
    margin-bottom: 20px;
    font-size: 1vw;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
    justify-content: space-between;
}

.type-dropdown div:nth-child(1) {
    display: flex;
    flex:0 0 82%;
}


.type-dropdown:hover{
    background-color: #558194 !important;
    border-radius: 4px;
    cursor: pointer;
    color: #fff !important;
    font-weight: 600;
    -webkit-transform: scale(1.1);
    padding: 5% 5% 5% 14%;
-moz-transform: scale(1.1);
-o-transform: scale(1.1);
transform: scale(1.1);
-webkit-transition: all 0.3s ease-in-out;
-moz-transition: all 0.3s ease-in-out;
-o-transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
}

.type-dropdown:hover .sidebar-link-icon{
	filter:  brightness(0) invert(1);
}
.dropdown-list a{
    margin-bottom: 5px !important;
    padding: 4% 0% 0% 0% !important;
    height: 40px;
}
.dropdown-list a:hover{
    border-radius: 4px !important;
}
@media (min-width: 768px) and (max-width:1024px){
	.right_side_section {
        width: calc( 100% - 17em );
    }
    .down_icon {
        margin-left: 7px;
    }
    .side_bg{
        padding: 3rem 0 0 0rem;
        width: 25.666%;
    }
    .side_bg li span {
        margin-right: 6px;
    }
    .top_link {
        margin-left: 0px;
    }
    .logo_im {
        width: 130px;
    }
    .logout_dropdown.dropdown-toggle::after {
        margin-left: 0.255em;
    }
}
@media (min-width: 1025px) and (max-width:1300px){
    .side_bg {
        width: 24.666%;}
    .down_icon {
     margin-left: 28px;
    }
}
@media (min-width: 1600px){
    .right_side_section {
        width: calc( 100% - 22em );
    }
}

@media (min-resolution: 100dpi) and (max-resolution: 110dpi){
    .side_bg{
        width: 17.666%; /* 200 */}
	.right_side_section {
		width: 75%;
		width: calc( 100% - 20em );}
}
/* @media (min-resolution: 90dpi) and (max-resolution: 90dpi){
	.right_side_section {
		width: 75%;
		width: calc( 100% - 24em ) !important;
    }
} */
.side_bg .accordion-item {
    background-color: transparent;
    border: 0px;
}
.accordion-button{
    background-color: transparent;
    padding: 0rem 0.1rem;
}
.accordion-button:hover{
    color: #fff;
}
@media (min-resolution: 90dpi) and (max-resolution: 100dpi){
    .side_bg{
        width: 17.666%; /* 200 */}
	.right_side_section {
		width: 75%;
		width: calc( 100% - 23em );}
}
@media (min-resolution: 130dpi) and (max-resolution: 140dpi){
    .side_bg{
        width: 17.666%; /* 200 */}
	.right_side_section {
		width: 75%;
		width: calc( 100% - 17em );}
}

/* .bottom_link button{
    border: 0px;
    background-color: transparent;
}
.bottom_link button:hover{
    color: #fff;
} */
.table{
    width: 100%;
}
.td_item{
    border-bottom: 1px #EEEEEE solid !important;
    padding: 10px;
    background-color: #ffffff !important;
    height: 73px;
}
.th_item{
    background-color: #F5F1E8 !important;
    height: 50px;  
}


.tr_items_list{
    background-color: #ffffff !important;
    padding: 15px !important;
}



section {
	position: relative;
	width: 100%;
	height: 100vh;
	display: flex;
	background: #f7f7f7;
}
.login-section{
	background-color: #fff !important;
}
section .img-box {
	position: relative;
	width: 50%;
}
section .img-box:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* background: linear-gradient(225deg, #e91e63, #ffd072); */
	z-index: 1;
	mix-blend-mode: screen;
}
section .img-box img {
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	object-fit: cover;
}
section .content-box {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50%;
	height: 100%;
}
section .content-box .brand-img {
	padding-bottom: 3rem;
	width: 200px;
	display: block;
}
section .content-box .form-box {
	width: 60%;
}
section .content-box .form-box h2 {
	color: black;
	font-weight: 700;
	font-size: 36px;
	text-transform: capitalize;
	margin-bottom: 20px;
	/* border-bottom: 4px solid #ff4584; */
	display: inline-block;
	letter-spacing: 1px;
}
section .content-box .form-box .input-box {
	margin-bottom: 15px;
	/* margin: 1rem 0; */
}
section .content-box .form-box .input-box span {
	font-size: 16px;
	margin-bottom: 10px;
	display: inline-block;
	color: #2D232E;
	font-weight: 400;
	letter-spacing: 1px;
}
section .content-box .form-box .input-box input {
	width: 100%;
	padding: 15px 20px;
	outline: none;
	font-weight: 400;
	border: 1px solid #c6c6c6;
	font-size: 16px;
	letter-spacing: 1px;
	color: #c6c6c6;
	background: #fff;
	border-radius: 7.75px;
}
section .content-box .input-box input::placeholder {
	font-weight: 400;
}
section .content-box .input-box input[type='submit'] {
	background: #558194;
	color: #fff;
	outline: none;
	border: none;
	font-weight: 500;
	cursor: pointer;
	text-transform: uppercase;
}
section .content-box .input-box input[type='submit']:hover {
	background: #558194;
}
section .content-box .remember {
	margin-bottom: 20px;
	margin-top: 40px;
	color: #2D232E;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 400;
	font-size: 16px;
}
section .content-box .remember .checkbox {
	margin-right: .45rem;
}
section .content-box .form-box .forget-password a {
	text-decoration: none;
	color: #558194;
	display: flex;
    justify-content: end;
}
@media (max-width: 980px) {
	section .img-box {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
	section .content-box {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	section .content-box .brand-img {
		padding-bottom: 2rem;
	}
	section .content-box .form-box {
		width: 100%;
		padding: 40px;
		background: rgba(255, 255, 255, .85);
		margin: 50px;
	}
}
@media (max-width: 480px) {
	section .content-box .brand-img {
		padding-bottom: 2rem;
	}
	section .content-box .form-box {
		margin: 10px;
		padding: 20px;
	}
} 